import React, { useState } from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp"

function Subscribe(props) {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await addToMailchimp(e.target[0].value)
    setError(res.result === 'error')
    e.target[0].value = ''
    setLoading(false)
  }

  return (
    <div className="relative">
      <div className="space-y-2">
        <form onSubmit={handleSubmit} className="flex space-x-4">
          <input name="email" type="email" placeholder="Your Email" className={`px-6 py-2 lg:w-72 rounded-full text-black inline border border-black-faded outline-none focus:ring ring-blue ${error ? 'border-error border-2' : ''}`} />
          <button type="submit" className="px-6 py-2 rounded-full text-white font-medium inline text-center bg-blue" target="_blank" rel="noopener noreferrer">Subscribe</button>
        </form>
        {error && "That email is not valid"}
      </div>

      {loading && <div className="absolute inset-0 bg-white flex items-center justify-center">
        Loading...
      </div>}
    </div>
  );
}

export default Subscribe;